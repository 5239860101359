import React from 'react'

import { ButtonNew, IconNew } from '..'
import { LocationContext, MicroCopyContext } from '../../components/page-wrapper'
import RichText from '../../components/rich-text'
import { ImageFluid } from '../../utils/image'
import { getMicroCopy } from '../../utils/microcopy'
import { getUrl } from '../../utils/urls'
import { BUTTON_VARIANT } from '../ButtonNew/ButtonNew'

const FeatureContact = ({
  image,
  icon,
  title,
  disclaimer,
  description,
  action,
  extraInfo = [],
  price,
  month,
  bulletListColor
}) => {
  const location = React.useContext(LocationContext)
  const { slugPrefix } = location
  const getExtraInfoKey = () => {
    if (!Array.isArray(extraInfo)) return
    const setKey =
      extraInfo.find((eInfo) => eInfo['__typename'] === 'ContentfulMicrocopySet')?.key || ''
    const itemKey =
      extraInfo.find((eInfo) => eInfo['__typename'] === 'ContentfulMicrocopy')?.key || ''

    if (!setKey || !itemKey) return null
    return `${setKey}.${itemKey}`
  }

  const microCopyData = React.useContext(MicroCopyContext)
  return (
    <div
      className={
        'c-feature-contact l-grid l-grid__col:12/12 l-grid__col:6/12@at:viewport-7 l-grid__col:4/12@at:viewport-9'
      }
    >
      {image ? <ImageFluid image={image} /> : null}
      <div className={'c-feature-contact__card'}>
        <div className={'c-feature-contact__header'}>
          <div className={'c-feature-contact__title'}>
            {icon ? (
              <IconNew
                name={icon}
                classes={`c-feature-card-list__items-icon c-feature-card-list__items-icon--${bulletListColor}`}
              />
            ) : null}
            {title ? <h4>{title}</h4> : null}
          </div>
          {Array.isArray(extraInfo) && extraInfo.length > 0 ? (
            <div className="c-feature-contact__extraInfo">
              {typeof extraInfo === 'object'
                ? getMicroCopy({
                    key: getExtraInfoKey(),
                    data: microCopyData,
                    tokens: { period: month, price: price }
                  })
                : extraInfo}
            </div>
          ) : null}
        </div>
        {description ? <RichText data={description} tokens={{}} /> : null}
        {action ? (
          <ButtonNew variant={BUTTON_VARIANT.TERTIARY} url={getUrl(action, slugPrefix)} size={'md'}>
            {action.title} <IconNew name={'ti-arrow-narrow-right'} />
          </ButtonNew>
        ) : null}
      </div>
    </div>
  )
}

export default FeatureContact
