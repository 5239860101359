import cn from 'classnames'
import React from 'react'

//
import { ImageFluid } from '../../utils/image'

const HeroIntegrationVisual = ({ integrations = [], classes = '' }) => {
  return (
    <div className={cn('c-hero-integration-visual', classes)}>
      <div className="lines lines-right u-display:none@to:viewport-7"></div>
      <div className="lines lines-left u-display:none@to:viewport-7"></div>

      <div className="c-hero-integration-visual__container">
        {integrations.length > 0 &&
          integrations.map((item, index) => {
            return (
              <>
                <div key={item.id} className="c-hero-integration-visual__integration">
                  <div className="c-hero-integration-visual__visual">
                    <ImageFluid
                      className="c-hero-integration-visual__image"
                      image={{
                        url: item.image?.file?.url || item.image || null
                      }}
                      containImages={true}
                    />

                    <span className="c-hero-integration-visual__borders">
                      <span className="c-hero-integration-visual__border"></span>
                    </span>
                  </div>
                </div>
                {index !== integrations.length - 1 ? (
                  <div className="c-hero-integration-visual__separator"></div>
                ) : null}
              </>
            )
          })}
      </div>
    </div>
  )
}

export default HeroIntegrationVisual
