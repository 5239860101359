import React from 'react'

//
import { HeroIntegration, Kpi } from '../../../storybook/'
import { getUrl } from '../../../utils/urls'
import { LocationContext } from '../../page-wrapper'

const BlockIntegrationHero = ({
  data: { title, description, action, uspBlock, partnerIntegration }
}) => {
  const partnerIntegrations = partnerIntegration?.map((item) => ({
    id: item?.svgIcon?.id,
    label: item?.svgIcon?.title,
    image: item?.svgIcon?.file?.url,
    width: item?.svgIcon?.file?.details.width,
    height: item?.svgIcon?.file?.details.height
  }))

  const location = React.useContext(LocationContext)
  const { slugPrefix } = location

  return (
    <>
      <HeroIntegration
        title={title}
        type={{}}
        integrations={partnerIntegrations}
        content={description || ''}
        primaryButton={{ label: action?.title || '', url: getUrl(action, slugPrefix) || '#' }}
      />
      {uspBlock && (
        <section className="l-section">
          <div className="l-container:12/12 l-container--default-spacing">
            <div className="l-section__content">
              <div className="l-grid l-grid--default-spacing">
                {uspBlock?.map((block, i) => (
                  <div
                    key={`${block.id}-${i}`}
                    className="l-grid_col:12/12 l-grid__col:4/12@at:viewport-7"
                  >
                    {
                      // @todo: check token!
                    }
                    <Kpi icon={block.icon || ''} description={block?.description || ''} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default BlockIntegrationHero
