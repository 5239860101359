/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import cn from 'classnames'
import React, { useState } from 'react'

import { ImageFluid } from '../../utils/image'
import TablerIconPlay from '../Icon/icons/topic/player-play.svg'
import { withAnchor } from '../hooks'
//

export const VIDEO_SOURCES = Object.freeze({
  Wistea: 'Wistea',
  Youtube: 'Youtube'
})

const Wistia = ({
  videoId = '',
  thumbnailVideo = null,
  thumbnail = null,
  videoSource = VIDEO_SOURCES.Youtube
}) => {
  const [isPlaying, setIsPlaying] = useState(false)

  const handleIsPlaying = () => {
    if (videoSource === VIDEO_SOURCES.Youtube) {
      setIsPlaying(!isPlaying)
    }
  }

  if (!videoId) return

  if (videoSource === VIDEO_SOURCES.Youtube) {
    const thumbnailContent = thumbnailVideo ? (
      thumbnail
    ) : thumbnail ? (
      <ImageFluid image={thumbnail} />
    ) : null
    return (
      <>
        <div className="c-media__youtube" onClick={() => handleIsPlaying()}>
          {thumbnailContent || (
            <img
              src={`https://img.youtube.com/vi/${videoId}/sddefault.jpg`}
              alt="Youtube Video Thumbnail"
            />
          )}
          <div className={cn('c-media__button', { 'is-hidden': isPlaying })}>
            <button aria-label="Play Button" onClick={handleIsPlaying}>
              <TablerIconPlay width="40" height="40" fill="#1c1c1c1" />
            </button>
          </div>
        </div>
        {isPlaying ? (
          <YTModal videoId={videoId} autoPlay={true} closeModal={handleIsPlaying} />
        ) : null}
      </>
    )
  }

  return <></>
}

const YTModal = ({ videoId, autoPlay, closeModal = () => {} }) => {
  return (
    <div
      className={cn('c-media__yt-full-width', {
        'c-media__yt-full-width--isOpen': true
      })}
      onClick={(event) => {
        event.preventDefault()
        event.stopPropagation()
        closeModal()
      }}
    >
      <iframe
        title="YouTube video player"
        allowFullScreen
        height="315px"
        src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1${
          autoPlay ? `&autoplay=1` : ''
        }`}
        width="560px"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      />
    </div>
  )
}

export default withAnchor(Wistia)
