import cn from 'classnames'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'

import RichText from '../../../components/rich-text'
import { SectionHeader, PricingCard, Loader, IconNew, ButtonNew } from '../../../storybook'
import { BUTTON_VARIANT } from '../../../storybook/ButtonNew/ButtonNew'
import { PRICING } from '../../../utils/common'
import { getMicroCopy } from '../../../utils/microcopy'
import { getUrl } from '../../../utils/urls'
import { MicroCopyContext, SiteDataContext } from '../../page-wrapper'

export const BILLING_TIME_MONTHLY = PRICING.BILLING_TIME_MONTHLY
export const BILLING_TIME_ANNUALLY = PRICING.BILLING_TIME_ANNUALLY
export const PAY_MONTHLY_KEY = PRICING.PAY_MONTHLY_KEY
export const PAY_ANNUALLY_KEY = PRICING.PAY_ANNUALLY_KEY

const BlockPricingCards = ({ data }) => {
  const microCopyData = React.useContext(MicroCopyContext)
  const { market, isTextRtl } = React.useContext(SiteDataContext)
  const { tiers } = market.pricing
  const { slugPrefix } = data.extra

  const [cardsShowMore, setCardsShowMore] = React.useState(false)

  if (isEmpty(tiers)) {
    return null
  }

  const [cards, setCards] = React.useState([])

  const getExtraInfoKey = (price) => {
    if (!price) return

    const component = (
      <>
        <strong>
          {getMicroCopy({ key: 'pricing.from', fallback: 'From', data: microCopyData })}{' '}
          {market?.currencySymbol ? market.currencySymbol : '$'}
          {price}
        </strong>
        /{getMicroCopy({ key: 'pricing.month', data: microCopyData })}
      </>
    )

    return component
  }

  useEffect(() => {
    function extendCardsByPrices() {
      return data.pricingCards.map((card) => {
        const relatedPrice = tiers.find((tier) => card.pricingTier === tier.tierTitle)

        if (relatedPrice) {
          return {
            ...relatedPrice,
            ...card
          }
        }

        return null
      })
    }

    setCards(extendCardsByPrices)
  }, [])

  const isPricingCards = !!cards?.length

  return (
    <section
      className={cn('c-pricing-cards l-container:12/12to:viewport-9', {
        'c-pricing-cards--rtl': isTextRtl
      })}
    >
      <SectionHeader
        title={data.title || ''}
        headingType="h1"
        align="center"
        classes="c-pricing-cards__header l-container:10/12 l-container:1/12@to:viewport-7"
      />

      {isPricingCards ? (
        <>
          <div className="c-pricing-cards__bottom-layout l-container:12/12 l-container--default-spacing">
            <div>
              {<RichText data={data.description} classes={'c-pricing-cards__cards-title'} />}
            </div>
            <div className="c-pricing-cards__cards-wrapper">
              {data?.pricingCards.map((card) => (
                <PricingCard
                  key={card.id}
                  card={card}
                  slugPrefix={slugPrefix}
                  microCopy={microCopyData}
                  showMore={cardsShowMore}
                  toggleShowMore={setCardsShowMore}
                />
              ))}
            </div>
          </div>
        </>
      ) : (
        <section className="l-section">
          <div className="l-container:12/12 l-container--default-spacing">
            <Loader />
          </div>
        </section>
      )}
      {data.moreSolutions && (
        <div className="c-pricing-cards__more-solutions l-container:12/12 l-container--default-spacing">
          <p className="c-pricing-cards__more-solutions__title">{data.moreSolutions.title}</p>
          {data.moreSolutions.featureCards.map((card, i) => {
            return (
              <div key={`${card.id}-${i}`} className="c-pricing-cards__more-solutions__card">
                <div className="c-pricing-cards__more-solutions__card__info-container">
                  {card.image ? (
                    <div className="c-pricing-cards__more-solutions__card__info-container__image">
                      <img
                        loading="lazy"
                        src={card.image.file.url}
                        alt={`${card.title} icon`}
                        style={{
                          width: 32,
                          height: 32,
                          objectFit: 'contain'
                        }}
                      />
                    </div>
                  ) : card.icon ? (
                    <IconNew icon={card.icon} width={32} height={32} />
                  ) : null}
                  <div className="c-pricing-cards__more-solutions__card__info-container__text-container">
                    <div className="c-pricing-cards__more-solutions__card__title">{card.title}</div>
                    <div className="c-pricing-cards__more-solutions__card__description">
                      <RichText data={card.description} />
                    </div>
                  </div>
                </div>
                <div className="c-pricing-cards__more-solutions__card__price-container">
                  {card.price ? (
                    <div className="c-pricing-cards__more-solutions__card__extraInfo">
                      {getExtraInfoKey(card.price)}
                    </div>
                  ) : null}
                  {card.action && (
                    <ButtonNew
                      variant={BUTTON_VARIANT.PRIMARY}
                      url={getUrl(card.action, slugPrefix)}
                      size={'md'}
                    >
                      {card.action.title}
                    </ButtonNew>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      )}
    </section>
  )
}

export default BlockPricingCards
